"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createVirtualBackground = void 0;
var virtualBackgroundEffect_1 = require("./virtualBackgroundEffect");
function createVirtualBackground() {
    return {
        apply: function (stream, virtualBackgroundUrl) {
            var track = virtualBackgroundEffect_1.virtualBackgroundEffect.start(stream);
            if (virtualBackgroundUrl === 'blur') {
                virtualBackgroundEffect_1.virtualBackgroundEffect.applyBlur();
            }
            else {
                virtualBackgroundEffect_1.virtualBackgroundEffect.applyImageBackground(virtualBackgroundUrl);
            }
            return track;
        },
        destroy: function () {
            virtualBackgroundEffect_1.virtualBackgroundEffect.stop();
        },
    };
}
exports.createVirtualBackground = createVirtualBackground;
