"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEncodings = void 0;
// Used for VP9 webcam video.
var VIDEO_KSVC_ENCODINGS = [
    { scalabilityMode: 'S3T3_KEY' },
];
// Used for VP9 desktop sharing.
var VIDEO_SVC_ENCODINGS = [
    { scalabilityMode: 'S3T3', dtx: true },
];
/**
 * 获取编码参数
 */
var getEncodings = function (rtpCapabilities, simulcastProfiles, width, height, screenSharing) {
    var _a;
    if (!width || !height) {
        throw new Error('missing width or height');
    }
    // If VP9 is the only available video codec then use SVC.
    var firstVideoCodec = (_a = rtpCapabilities.codecs) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.kind === 'video'; });
    if (!firstVideoCodec) {
        throw new Error('No video codecs');
    }
    var encodings;
    var size = width > height ? width : height;
    if (firstVideoCodec.mimeType.toLowerCase() === 'video/vp9') {
        encodings = screenSharing ? VIDEO_SVC_ENCODINGS : VIDEO_KSVC_ENCODINGS;
    }
    else {
        encodings = chooseEncodings(simulcastProfiles, size);
    }
    return encodings;
};
exports.getEncodings = getEncodings;
/**
 * 根据大小选择编码
 */
var chooseEncodings = function (simulcastProfiles, size) {
    var e_1, _a;
    var encodings = [];
    var sortedMap = new Map(__spreadArray([], __read(Object.entries(simulcastProfiles)), false).sort(function (a, b) { return parseInt(b[0]) - parseInt(a[0]); }));
    try {
        for (var sortedMap_1 = __values(sortedMap), sortedMap_1_1 = sortedMap_1.next(); !sortedMap_1_1.done; sortedMap_1_1 = sortedMap_1.next()) {
            var _b = __read(sortedMap_1_1.value, 2), key = _b[0], value = _b[1];
            if (parseInt(key) < size) {
                if (encodings === null) {
                    encodings = value;
                }
                break;
            }
            encodings = value;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (sortedMap_1_1 && !sortedMap_1_1.done && (_a = sortedMap_1.return)) _a.call(sortedMap_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    // hack as there is a bug in mediasoup
    if (encodings.length === 1) {
        encodings.push(__assign({}, encodings[0]));
    }
    return encodings;
};
